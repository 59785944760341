import React from "react"
import { Link } from "gatsby"
import PageLayout from "../components/new-pagelayout"

const LoginPage = () => {
  return (
    <PageLayout pageTitle={"Om företaget"}>
      <section
        data-section-id="1"
        data-category="sign-in"
        data-component-id="da51_12_03_awz"
        class="relative py-20 bg-white"
      >
        <div class="absolute top-0 left-0 lg:bottom-0 h-128 lg:h-auto w-full lg:w-8/12 bg-white"></div>
        <div class="relative container px-4 mx-auto">
          <div class="flex flex-wrap items-center -mx-4">
            <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
              <div class="max-w-lg">
                <h2
                  class="mb-10 text-4xl font-semibold font-heading"
                  data-config-id="header"
                >
                  Hantera dina larm online
                </h2>
                <p class="text-xl text-gray-500" data-config-id="desc">
                  Har du inget konto? Kontakta oss för produktdemo. När
                  larminstallationen är klar så får du dina
                  inloggningsuppgifter.{" "}
                </p>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="lg:max-w-md p-6 lg:px-10 lg:py-12 bg-white text-center border rounded-xl">
                <form action="#">
                  <span
                    class="inline-block mb-4 text-xs text-blue-400 font-semibold"
                    data-config-id="caption"
                  >
                    Logga in
                  </span>
                  <h3
                    class="mb-12 text-3xl font-semibold font-heading"
                    data-config-id="form-header"
                  >
                    Administera ditt larm
                  </h3>
                  <div class="relative flex flex-wrap mb-6">
                    <input
                      class="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded"
                      type="email"
                      placeholder="e.g hello@shuffle.dev"
                    ></input>
                    <span class="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">
                      Din emailadress
                    </span>
                  </div>
                  <div class="relative flex flex-wrap mb-6">
                    <input
                      class="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded"
                      type="password"
                      placeholder="******"
                    ></input>
                    <span class="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">
                      Lösenord
                    </span>
                  </div>
                  <button
                    class="w-full inline-block py-4 mb-4 text-sm text-white font-medium leading-normal bg-red-400 hover:bg-red-300 rounded transition duration-200"
                    data-config-id="primary-action"
                  >
                    Logga in
                  </button>
                  <Link
                    class="block pb-2 mb-2 text-sm font-semibold hover:underline text-gray-500" // border-b"
                    to="/kontakt"
                    data-config-id="secondary-action"
                  >
                    Glömt lösenord?
                  </Link>
                  {/*
                  <a
                    class="flex items-center justify-center mb-4 py-4 bg-blue-800 hover:bg-blue-900 rounded"
                    href="#"
                  >
                    <span class="inline-block mr-2">
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.0898 11.8182V6.51074H5.90537L6.17776 4.4417H4.0898V3.12092C4.0898 2.52207 4.25864 2.11397 5.13515 2.11397L6.25125 2.11352V0.262891C6.05824 0.238289 5.39569 0.181885 4.62456 0.181885C3.01431 0.181885 1.9119 1.14594 1.9119 2.91601V4.4417H0.0908203V6.51074H1.9119V11.8182H4.0898Z"
                          fill="white"
                        ></path>
                      </svg>
                    </span>
                    <span class="text-sm text-white" data-config-id="fb-action">
                      Logga in med Facebook
                    </span>
                  </a>
                  */}
                  {/*
                  <a
                    class="flex items-center justify-center py-4 bg-blue-400 hover:bg-blue-500 rounded"
                    href="#"
                  >
                    <span class="inline-block mr-2">
                      <svg
                        width="13"
                        height="11"
                        viewBox="0 0 13 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.5455 2.0974C12.0904 2.29904 11.6022 2.43579 11.0892 2.49684C11.613 2.18316 12.014 1.68562 12.204 1.09459C11.7127 1.38508 11.1703 1.59601 10.5924 1.71035C10.1296 1.21667 9.47138 0.90918 8.74128 0.90918C7.34059 0.90918 6.20489 2.04488 6.20489 3.4448C6.20489 3.64334 6.2273 3.83726 6.27057 4.0227C4.16298 3.91684 2.29411 2.90708 1.0433 1.37272C0.824652 1.74665 0.700269 2.18237 0.700269 2.64748C0.700269 3.52746 1.14837 4.30391 1.82825 4.75817C1.41259 4.74427 1.02166 4.62993 0.67942 4.43987V4.47154C0.67942 5.69995 1.55399 6.72516 2.71362 6.95849C2.50116 7.01567 2.27712 7.04734 2.04534 7.04734C1.88156 7.04734 1.72318 7.03112 1.56788 7.00021C1.89081 8.00843 2.8272 8.74161 3.93663 8.76171C3.06902 9.44159 1.97504 9.84565 0.786814 9.84565C0.582087 9.84565 0.38043 9.83328 0.181885 9.81088C1.30445 10.5317 2.63716 10.952 4.06952 10.952C8.73514 10.952 11.2854 7.08752 11.2854 3.73607L11.2769 3.40773C11.7752 3.05231 12.2063 2.60577 12.5455 2.0974Z"
                          fill="white"
                        ></path>
                      </svg>
                    </span>
                    <span
                      class="text-sm text-white"
                      data-config-id="twitter-action"
                    >
                      Logga in med Twitter
                    </span>
                  </a>
                  */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}

export default LoginPage
